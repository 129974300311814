import React from 'react';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import componentPicker from '../util/componentPicker';
import Layout from '../components/Layout';

const Page = ({ pageContext: { page } }) => {
    return (
        <Layout>
            <Seo post={page} />
            {page.blocks.map((block, i) => (
                <React.Fragment key={i}>
                    {componentPicker(block)}
                </React.Fragment>
            ))}
        </Layout>
    );
};

export default Page;
